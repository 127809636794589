<template>
  <div class="article-add">
    <l-title-card
        name="编辑文章"
        need-back="true"
        back-name="articleList"
    >
      <el-button
          type="primary"
          icon="el-icon-check"
          @click="updateArticle"
      >
        保存
      </el-button>
    </l-title-card>
    <div class="main-content-container">
      <el-form
          :model="dataInfo"
          label-width="60px"
      >
        <el-form-item
            label="标题"
            prop="title"
        >
          <el-input
              v-model="dataInfo.title"
              placeholder="请输入文章标题"
          >
          </el-input>
        </el-form-item>
        <el-form-item
            label="分类"
            prop="typeId"
        >
          <el-select
              placeholder="请选择分类"
              v-model="dataInfo.typeId"
          >
            <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
              {{ item.name }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="标签"
            prop="tagList"
        >
          <el-select
              v-model="dataInfo.tagList"
              placeholder="请选择标签"
              multiple
          >
            <el-option
                v-for="item in tagList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
              {{ item.name }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="简介"
            prop="description"
        >
          <el-input
              type="textarea"
              v-model="dataInfo.description"
              placeholder="请输入文章简介"
          >
          </el-input>
        </el-form-item>
        <el-form-item
            label="关键词"
            prop="keywords"
        >
          <el-input
              type="text"
              v-model="dataInfo.keywords"
              placeholder="请输入关键词"
          >
          </el-input>
        </el-form-item>
        <el-form-item
            label="缩略图"
            prop="thumb"
        >
          <el-upload
              class="avatar-uploader"
              action="https://upload-z2.qiniup.com"
              :data="qn"
              :show-file-list="false"
              :on-success="uploadSuccess"
              :on-error="uploadFailed"
              :before-upload="beforeUpload"
              :disabled="!qn.token"
          >
            <img
                v-if="dataInfo.thumbUrl"
                :src="dataInfo.thumbUrl"
                class="avatar"
            >
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
            label="正文"
            prop="content"
        >
          <mavon-editor
              v-model="dataInfo.content"
              codeStyle="hopscotch"
          >
          </mavon-editor>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "add",
  data() {
    return {
      dataInfo: {
        title: null,
        typeId: null,
        tagList: null,
        description: null,
        content: "",
        keywords: null,
        thumbUrl: null
      },
      qn: {
        key: null,
        token: null
      },
      typeList: [],
      tagList: []
    };
  },
  async created() {
    await this.checkTokenInfo();
    await this.getTagList();
    await this.getTypeList();
    await this.getArticleDetails();
  },
  methods: {

    /**
     * @description: 检查上传Token
     */
    checkTokenInfo() {
      let token = localStorage.getItem("uploadToken");
      if (token) {
        this.qn.token = token;
      } else {
        this.getUploadToken();
      }
    },

    /**
     * @description 获取标签列表数据
     */
    async getTagList() {
      this.tagList = await this.$api.tag.getTagList();
    },

    /**
     * @description 获取分类列表数据
     */
    async getTypeList() {
      this.typeList = await this.$api.type.getTypeList();
    },

    /**
     * @description 获取文章详情
     */
    async getArticleDetails() {
      const result = await this.$api.article.getArticleDetails({
        id: this.$route.params.id
      });
      if (result) {
        this.dataInfo = result;
        this.dataInfo.tagList = result.tagList.map(item => {
          return item.id
        })
        this.dataInfo.typeId = this.dataInfo?.typeInfo?.id;
      }
    },

    /**
     * @description 添加新的文章
     */
    async updateArticle() {
      const result = await this.$api.article.editArticleInfo(this.dataInfo);
      if (result) {
        this.$router.push({
          path: "/"
        });
      }
    },

    /**
     * @description 从后台获取上传凭证
     */
    async getUploadToken() {
      this.qn.token = await this.$api.article.getUploadToken();
      localStorage.setItem("uploadToken", this.qn.token);
    },

    /**
     * @description 图片上传成功后处理
     * @param res
     */
    uploadSuccess(res) {
      this.dataInfo.thumbUrl = this.$imgBasePath + res.key;
    },

    /**
     * @description 图片上传失败，重新获取Token后上传
     */
    uploadFailed() {
      this.getUploadToken();
      this.$notify({
        type: "error",
        title: "上传失败",
        message: "文件上传失败，请重新选择文件后进行上传"
      });
    },

    /**
     * @description 上传前处理
     */
    beforeUpload() {
      this.qn.key = new Date().getTime();
    }
  }
};
</script>

<style lang="scss">

</style>
